 
export const app = {
  mobile: (state) => state.app.mobile, 
}

export const pages = {
  page:(state) => state.pages.page,
  // header_visible:(state) => state.pages.header_visible,
  // active:(state) => state.pages.active,
}

export const footer = {
  tab:(state) => state.footer.tab,
  hidden:(state) => state.footer.hidden, 
}
 